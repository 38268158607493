.video-container {
    padding: 32px 16px;
}

.video-block {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.video-block iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}



@media only screen and (min-width: 768px) { 
    .video-container {
        padding: 32px 15vw;
    }
    
    .video-block {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
    }
    
    .video-block iframe {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }    
}